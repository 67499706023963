@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../../dist/font/HelveticaNeue-Bold.woff2') format('woff2'),
        url('../../dist/font/HelveticaNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: 'Helvetica Neue';
	font-weight: bold;
	font-size: 1rem;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 0.9375rem;
	}

	@include tablet-down {
		font-size: 0.875rem;
	}

	@include phone-down {
		font-size: 0.8125rem;
	}

	@include phone-sm-down {
		font-size: 0.75rem;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

p,li,ul,a,img,h1,h2,h3,h4{
	margin: 0;
	line-height: 1.4;
}

header {

}

.split{
	@include flex(column,initial,initial);
	@include tablet-up{
		@include flex(row,initial,initial);
	}
}

.social{
	@include flex(row,center,center);
	gap: 0.625rem;
	a{
		img{
			width: 1.875rem;
		}
	}
	@include tablet-up{
		gap: 1.25rem;
		a{
			img{
				width: 2.5rem;
			}
		}
	}
}

.has-bg{
	position: relative;
	.bg{
		position: absolute;
			top: 0;
			left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
	.content{
		position: relative;
	}
}

.section-heading{
	color: $purple;
	text-transform: uppercase;
	margin-bottom: 1.875rem;
	font-size: 1.875rem;
	@include tablet-up{
		margin-bottom: 3.75rem;
		font-size: 3.75rem;
	}
}

main {

	section{


		&#hero-new{
			position: relative;
			img{
				width: 100%;
				height: auto;
			}
			border-bottom: solid 0.875rem $purple;
			.social{
				position: absolute;
				z-index: 10;
				top: 72%;
				left: 72.25%;
				transform: translateX(-50%);
				img{
					filter: brightness(0) invert(1);
				}
				@media screen and (max-width: 68.75rem){
					width: 13.75rem;
					gap: 0.625rem;
					img{
						width: 2.1875rem;
					}

				}
				@media screen and (max-width: 26.25rem){
					left: 50%;
					top: auto;
					bottom: 0.625rem;
				}
			}
		}

		&#hero-new-2{
			background-image: url('../../dist/img/hero-bg.png');
			background-size: cover;
			background-position: top left;
			background-repeat: no-repeat;
			.split{
				flex-direction: column-reverse;
				.side{
					text-align: center;
					@include flex(column,center,center);
					img{
						display: inline-block;
						width: 100%;
					}
					&:last-child{
						padding: 1.875rem 0.9375rem;
						.social{
							margin-bottom: 1.25rem;
						}
						img{
							&.top{
								max-width: 16.25rem;
								margin-bottom: 1.25rem;
							}
							&.bottom{
								max-width: 10rem;
							}
						}
					}
				}
			}
			@include phone-up{
				.split{
					@include flex(row,initial,initial);
					.side{
						flex: 1;
						&:first-child{
							@include flex(column,flex-end,initial);
						}
						&:last-child{
							img{
								&.top{
									max-width: 25rem;
								}
								&.bottom{
									max-width: 15.625rem;
								}
							}
						}
					}
				}
			}
		}

		&#hero{
			border-bottom: solid 0.875rem $purple;
			.split{
				.side{
					.bg{
						opacity: .7;
					}
					.wrapper{
						padding: 0.9375rem;
						.logo{
							margin: 0.625rem auto;
							width: 100%;
							max-width: 15.625rem;
						}
						.logo-bottom{
							margin: 1.25rem auto 0;
						}
						// .copy{
						// 	width: 100%;
						// 	max-width: 33.125rem;
						// 	margin: 0 auto;
						// }
					}
					.photo{
						width: 100%;
						height: auto;
					}
				}
			}

			@include tablet-up{
				.split{
					.side{
						flex: 1;
						.photo{
							// height: calc(100vh - 0.875rem);
							object-fit: cover;
							object-position: top;
						}
						&:first-child{
							@include flex(column,center,center);
							.content{
								// height: calc(100vh - 0.875rem);
								@include flex(column,center,center);
								.wrapper{
									max-width: 43.75rem;
									@include flex(column,center,center);
									.logo{
										max-width: 25rem;
									}
								}
							}
						}
					}
				}
			}
		}

		&#tour{
			padding: 1.875rem 0;
			.section-heading{
				text-align: center;
			}
			#dates{
				max-width: 59.375rem;
				margin: 0 auto;
				.seated-events-table{
					border-top: none;
					.seated-event-row{
						// gap: 2.5rem;
						gap: 0.375rem;
						border-bottom: solid 0.0625rem $purple;
						padding: 1.25rem 0;
						.seated-event-description-cells{
							>div{
								flex: 1;
								text-transform: uppercase;
								&.seated-event-details-cell{
									// display: none;
									font-size: 0.75rem;
									margin-top: 0.375rem;
									color: $white;
									line-height: 1.4;
									@include tablet-up{
										width: 50%;
									}
								}
								&.seated-event-date-cell{
									margin-bottom: 0.375rem;
								}
								&.seated-event-venue-cell{
									gap: 0.375rem;
								}
							}
						}
						.seated-event-link2{
							border-radius: 0;
							background-color: $purple;
							text-transform: uppercase;
							font-size: 1.0625rem;
							color: transparent;
							padding: 0.375rem 1.25rem;
							border: solid 0.25rem $purple;
							min-width: 10rem;
							position: relative;
							&::after{
								content: 'VIP';
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								display: block;
								@include flex(column,center,center);
								color: $black;
								transition: all .4s;
							}
							@include hover-focus{
								&::after{
									color: $white;
								}
							}
						}
						.seated-event-link1{
							border-radius: 0;
							border: solid 0.25rem $purple;
							background-color: $white;
							text-transform: uppercase;
							font-size: 1.0625rem;
							padding: 0.375rem 1.25rem;
							min-width: 10rem;
							@include hover-focus{
								color: $blue;
							}
						}
					}
					>div{
						&:last-child{
							display: none;
						}
					}
				}
				&.closed{
					.seated-event-row:nth-child(n+6){
						display: none;
					}
				}
			}
			.link{
				display: block;
				max-width: max-content;
				margin: 3.75rem auto 0;
				background-color: $purple;
				border: solid 0.25rem $purple;
				padding: 0.625rem 1.875rem;
				color: $black;
				text-transform: uppercase;
				text-align: center;
				@include hover-focus{
					color: $white;
				}
				span:first-child{
					display: none;
				}
				&.closed{
						span:first-child{
								display: inline;
						}
						span:last-child{
								display: none;
						}
				}
			}
			@include tablet-up{
				padding: 3.75rem 0;
			}
		}

		&#store{
			padding: 3.75rem 0;
			text-align: center;
			.container{
				h2{
					font-size: 1.875rem;
					margin-bottom: 1.25rem;
				}
				img{
					display: inline-block;
				}
			}
			@include tablet-up{
				.container{
					max-width: rems(940);
					h2{
						font-size: 3.75rem;
					}
				}
			}
			.link{
				display: block;
				max-width: max-content;
				margin: 3.75rem auto 0;
				background-color: $purple;
				border: solid 0.25rem $purple;
				padding: 0.625rem 1.875rem;
				color: $black;
				text-transform: uppercase;
				text-align: center;
				@include hover-focus{
					color: $white;
				}
				span:first-child{
					display: none;
				}
				&.closed{
						span:first-child{
								display: inline;
						}
						span:last-child{
								display: none;
						}
				}
			}
		}

		&#music{
			padding: 1.875rem 0;
			.container{
				.split{
					gap: 1.25rem;
					.side{
						@include flex(column,center,center);
						.buttons{
							@include flex(column,initial,initial);
							gap: 1.25rem;
							a{
								@include flex(row,center,center);
								width: 100%;
								max-width: 21.25rem;
								background-color: $white;
								padding: 0.625rem 1.25rem;
								border: solid 0.25rem $purple;
								transition: all .4s;
								@include hover-focus{
									transform: scale(1.025);
								}
								img{
									height: 2rem;
								}
							}
						}
					}
				}
			}

			@include tablet-up{
				padding: 3.75rem 0;
				.container{
					max-width: 61.25rem;
					.split{
						justify-content: space-between;
						gap: 3.75rem;
						.side{
							.cover{
								width: 100%;
								aspect-ratio: 1/1;
							}
						}
					}
				}
			}
		}

		&#video{
			padding: 1.875rem 0;
			.container{
				iframe{
					width: 100%;
					max-width: 59.375rem;
					margin: 0 auto 1.25rem;
					aspect-ratio: 9/5;
				}
				h3{
					text-align: center;
					font-size: 1.25rem;
				}
			}
			@include tablet-up{
				padding: 3.75rem 0;
				.container{
					h3{
						font-size: 1.875rem;
					}
				}
			}
		}

		&#newsletter{
			padding: 1.875rem 0;
			.container{
				form{
					label{
						text-align: center;
						color: $purple;
						text-transform: uppercase;
						font-size: 1.875rem;
						margin-bottom: 1.875rem;
						display: block;
					}
					.inputs-wrap{
						@include flex(column,initial,initial);
						gap: 0.625rem;
						input{
							font-size: 0.875rem;
							padding-bottom: 0.625rem;
							background-color: transparent;
							border: none;
							border-bottom: solid 0.0625rem $white;
							color: $white;
							&::placeholder{
								color: $white;
							}
						}
						button{
							border-radius: 0;
							background-color: $purple;
							text-transform: uppercase;
							font-size: 1.0625rem;
							padding: 0.375rem 1.25rem;
							border: solid 0.25rem $purple;
							min-width: 10rem;
							@include hover-focus{
								color: $white;
							}
						}
					}
				}
			}
			@include tablet-up{
				padding: 3.75rem 0;
				.container{
					form{
						label{
							font-size: 3.75rem;
						}
						.inputs-wrap{
							@include flex(row,center,initial);
							input{
								font-size: 1.5rem;
							}
						}
					}
				}
			}
		}


	}

}

footer {
	padding: 1.875rem 0;
	.copyright{
		text-align: center;
		font-size: 0.75rem;
	}
}